.stage-cube-cont {
    width: 50%;
    height: 100%;
    top: 0;
    position: absolute;
    right: 0;
    overflow: hidden;
}

.cubespinner-container {
    position: relative;
    width: 100%;
    height: 100%;
}

.cubespinner {
    position: absolute;
    top: 35%;
    right: 60%;
    animation-name: spincube;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-duration: 12s;
    transform-style: preserve-3d;
    transform-origin: 100px 100px 0;

    div {
        position: absolute;
        width: 200px;
        height: 200px;
        border: 1px solid #ccc;
        background: rgba(1, 1, 1, 0.5);
        font-size: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0 0 15px 0px lightblue;
    }

    .face1 {
        transform: translateZ(100px);
        color: #dd0031;
    }
    .face2 {
        transform: rotateX(270deg) rotateY(90deg) translateZ(100px);
        color: #f06529;
    }
    .face3 {
        transform: rotateY(90deg) rotateX(90deg) translateZ(100px);
        color: #28a4d9;
    }
    .face4 {
        transform: rotateY(180deg) rotateZ(90deg) translateZ(100px);
        color: #5ed4f4;
    }
    .face5 {
        transform: rotateY(-90deg) rotateZ(90deg) translateZ(100px);
        color: #efd81d;
    }
    .face6 {
        transform: rotateX(-90deg) translateZ(100px);
        color: #ec4d28;
    }
}

.avatar-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.about-pic {
    margin-bottom: 30px;
    max-width: 250px;
    width: 100%;
    height: auto;
    border-radius: 50px;
    opacity: 0;
    animation: backInDown 1s 2s forwards;
}

@keyframes spincube {
    from,
    to {
        transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
    }
    16% {
        transform: rotateY(-90deg) rotateZ(90deg);
    }
    33% {
        transform: rotateY(-90deg) rotateX(90deg);
    }
    50% {
        transform: rotateY(-180deg) rotateZ(90deg);
    }
    66% {
        transform: rotateY(-270deg) rotateX(90deg);
    }
    83% {
        transform: rotateX(90deg);
    }
}