.skill-sphere {
    width: 50%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    overflow: hidden;
}

.stack-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    max-width: 700px;
}

.tech-stack {
    margin-top: 30px;
    display: flex;
    justify-content: center;
}

.technologies {
    display: flex;
    align-items: center;
    gap: 10px;

    svg {
        font-size: 56px;
    }
}

.skill-ticker {
    svg {
        font-size: 80px;
        filter: grayscale(1);
        transition: filter 0.3s ease-in-out;

        &:hover {
            animation: rubberBand .6s;
            filter: grayscale(0);
        }
    }

    margin-top: 50px;
}

.mobile-skill-sphere {
    width: 100%;
    height: 100%;
}

@media (max-width: 1100px) {
    span.tagcloud {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}