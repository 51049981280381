.comp-stage-cube-cont {
    width: 100%;
    height: 100%;
    overflow: hidden;
    height: 500px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.spinner-cube {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-12vh, -12vh);
}

.comp-cubespinner {
    animation-name: spincube;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-duration: 12s;
    transform-style: preserve-3d;
    transform-origin: 100px 100px 0;

    div {
        position: absolute;
        width: 200px;
        height: 200px;
        border: 1px solid #ccc;
        background: rgba(1, 1, 1, 0.5);
        font-size: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0 0 15px 0px lightblue;
    }

    .face1 {
        transform: translateZ(100px);
        color: #dd0031;
    }
    .face2 {
        transform: rotateX(270deg) rotateY(90deg) translateZ(100px);
        color: #f06529;
    }
    .face3 {
        transform: rotateY(90deg) rotateX(90deg) translateZ(100px);
        color: #28a4d9;
    }
    .face4 {
        transform: rotateY(180deg) rotateZ(90deg) translateZ(100px);
        color: #5ed4f4;
    }
    .face5 {
        transform: rotateY(-90deg) rotateZ(90deg) translateZ(100px);
        color: #efd81d;
    }
    .face6 {
        transform: rotateX(-90deg) translateZ(100px);
        color: #ec4d28;
    }
}

.cubespinner-container {
    position: relative;
    width: 100%;
    height: 100%;
}

.show-on-mobile {
    display: none;
}

@media (max-width: 1100px) {
    .show-on-mobile {
        display: block;
    }
}
