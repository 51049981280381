.mobile-map-container {
    margin-top: 50px;
    position: relative;
    height: 100%;
    display: none;
}

@media (max-width: 1100px) {
    .map-wrap {
        width: 100%;
    }

    .mobile-map-container {
        display: block;
    }
}