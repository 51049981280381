.page {
    width: 100%;
    height: 100%;
    position: absolute;
}

.top-tags {
    top: 15px;
}

.bottom-tags {
    bottom: 15px;
}

.tags {
    color: #0264e3;
    opacity: 0.6;
    position: absolute;
    left: 120px;
    font-size: 18px;
    font-family: "La Belle Aurore";
}

.top-tag-html {
    margin-left: -20px;
}

.bottom-tag-html {
    margin-left: -20px;
}

.container {
    width: 100%;
    will-change: contents;
    height: 100%;
    min-height: 566px;
    position: absolute;
    opacity: 0;
    top: 0;
    margin: 0 auto;
    z-index: 1;
    transform-style: preserve-3d;
    animation: fadeIn 1s forwards;
    animation-delay: 1s;
    overflow: hidden;
}

.scrollable-content {
    overflow-y: scroll;
    height: 450px;
    padding: 0 30px;
    margin-left: -30px;

    --scrollbar-width: 8px;

    --mask-height: 32px;

    overflow-y: auto;

    padding-bottom: var(--mask-height);
    padding-top: var(--mask-height);

    --mask-image-content: linear-gradient(
        to bottom,
        transparent,
        black var(--mask-height),
        black calc(100% - var(--mask-height)),
        transparent
    );

    --mask-size-content: calc(100% - var(--scrollbar-width)) 100%;

    --mask-image-scrollbar: linear-gradient(black, black);

    --mask-size-scrollbar: var(--scrollbar-width) 100%;

    mask-image: var(--mask-image-content), var(--mask-image-scrollbar);
    mask-size: var(--mask-size-content), var(--mask-size-scrollbar);

    mask-position:
        0 0,
        100% 0;

    mask-repeat: no-repeat, no-repeat;
}

.scrollable-content::-webkit-scrollbar {
    display: none;
}

.about-page,
.contact-page,
.skills-page {
    .text-zone {
        position: absolute;
        left: 10%;
        top: 50%;
        transform: translateY(-40%);
        width: 35%;
        vertical-align: middle;
        display: table-cell;
        max-height: 90%;

        h1 {
            font-size: 53px;
            font-family: "Coolvetica";
            color: #ffd700;
            font-weight: 400;
            margin-top: 0;
            position: relative;
            margin-bottom: 40px;
            left: 10px;

            &::before {
                content: "<h1>";
                font-family: "La Belle Aurore";
                font-size: 18px;
                position: absolute;
                margin-top: -10px;
                left: -10px;
                opacity: 0.6;
                line-height: 18px;
            }

            &::after {
                content: "</h1>";
                font-family: "La Belle Aurore";
                font-size: 18px;
                line-height: 18px;
                position: absolute;
                left: -30px;
                bottom: -20px;
                margin-left: 20px;
                opacity: 0.6;
            }
        }

        p {
            font-size: 15px;
            margin: 0;
            color: #fff;
            font-family: "Poppins";
            font-weight: 300;
            min-width: fit-content;
            animation: pulse 1s;
            text-align: center;

            &:nth-of-type(1) {
                animation-delay: 1.1s;
            }

            &:nth-of-type(2) {
                animation-delay: 1.2s;
            }

            &:nth-of-type(3) {
                animation-delay: 1.3s;
            }
            &:nth-of-type(4) {
                animation-delay: 1.4s;
            }
            &:nth-of-type(5) {
                animation-delay: 1.5s;
            }
        }
    }

    .text-animate-hover {
        &:hover {
            color: #fff;
        }
    }
}

.text-with-outline {
    text-shadow:
        -1px -1px 0 #000,
        1px -1px 0 #000,
        -1px 1px 0 #000,
        1px 1px 0 #000;
}

@media only screen and (max-width: 1100px) {
    .about-page,
    .skills-page,
    .contact-page {
        .text-zone {
            width: 100%;
            left: 0;
            margin-left: 0;
            top: 45%;

            h1 {
                display: inline-block;
                left: 10vh;
                margin-bottom: 0;
            }
        }

        .scrollable-content {
            margin-left: 0;
            padding: 50px 15px;

            h1 {
                margin-bottom: 30px;
            }
        }
    }
    .mobile-hidden {
        display: none;
    }
    .tags {
        left: 30px;
    }

    .page {
        height: 90%;
        bottom: 0;
    }
}

@media (max-width: 768px) {
    .about-page,
    .skills-page,
    .contact-page {
        .text-zone {
            h1 {
                font-size: 36px;
            }

            p {
                font-size: 14px;
            }
        }
    }
}