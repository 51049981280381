.home-page {
    overflow: hidden;

    .text-zone {
        position: absolute;
        left: 10%;
        top: 50%;
        transform: translateY(-50%);
        width: 500px;
        max-height: 90%;
    }

    h1 {
        color: #fff;
        font-size: 63px;
        margin: 0;
        font-family: "Coolvetica";
        font-weight: 500;
        position: relative;

        &::before {
            content: "<h1>";
            font-family: "La Belle Aurore";
            color: #ffd700;
            font-size: 18px;
            position: absolute;
            left: -30px;
        }

        &::after {
            content: "</h1>";
            font-family: "La Belle Aurore";
            color: #ffd700;
            font-size: 18px;
            position: absolute;
            margin-left: 20px;
            animation: fadeIn 1s 1.7s backwards;
            bottom: 0;
        }

        img {
            width: 74px;
            margin-left: 20px;
            opacity: 0;
            height: auto;
            animation: rotateIn 1s linear both;
            animation-delay: 1.4s;
        }

        .jobtitle {
            color: #0264e3;
            font-weight: 600;
        }
    }

    h2 {
        color: #ddd;
        margin-top: 20px;
        font-weight: 400;
        font-size: 12px;
        font-family: sans-serif;
        letter-spacing: 3px;
        animation: fadeIn 1s 1.8s backwards;
    }

    .flat-button {
        color: #ffd700;
        font-size: 13px;
        font-weight: 400;
        letter-spacing: 4px;
        font-family: sans-serif;
        text-decoration: none;
        padding: 10px 18px;
        border: 1px solid #ffd700;
        margin-top: 25px;
        float: left;
        animation: fadeIn 1s 1.8s backwards;
        white-space: nowrap;
        transition: all 0.3s ease-in;
        margin-right: 30px;

        &:hover {
            background: #ffd700;
            color: #333;
        }
    }
}

@media only screen and (max-width: 1100px) {
    .home-page {
        .text-zone {
            width: 100%;
            position: absolute;
            left: 10%;
            top: 50%;
            transform: translateY(-50%);
            max-height: 90%;
        }

        h1 {
            font-size: 54px;

            img {
                width: 34px;
                margin-left: 15px;
            }
        }
        h2 {
            padding-left: 20px;
            padding-right: 20px;
        }
    }
}

@media only screen and (max-width: 768px) {
    .home-page {
        .text-zone {
            left: 0;
            top: 30%;
            text-align: center;
        }

        h1 {
            font-size: 36px;

            &::before {
                left: 30%;
                font-size: 10px;
            }

            &::after {
                font-size: 10px;
                margin-left: 8px;
            }
        }

        h2 {
            font-size: 10px;
        }

        .flat-button-row {
            display: grid;
            place-items: center;

            .flat-button {
                margin-right: 0;
                font-size: 11px;
            }
        }
    }
}