.tagcloud--item {
    color: #fff;
    font-size: 22px;
    letter-spacing: 2px;
    font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;

    &:hover {
        color: #ffd700;
    }
}

.tagcloud {
    height: 100% !important;
}

@media (max-width: 1100px) {
    .tagcloud--item {
        font-size: 15px;
    }
}