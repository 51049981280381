.logo-container {
    z-index: -1;
    width: 400px;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(-50%, -50%);
    left: auto;
    margin: auto;
    animation: fadeIn 2s 2s forwards;
    opacity: 0;

    svg {
        width: 100%;
        height: auto;
        bottom: 0;
        transform: rotateZ(30deg) !important;
    }

    .solid-logo {
        position: absolute;
        top: auto;
        right: auto;
        bottom: auto;
        left: 0;
        margin: auto;
        width: 100%;
        z-index: 1;
        animation: fadeIn 1s 4s forwards;
        opacity: 0;
        transform: rotateZ(30deg);
    }
}

.svg-container {
    stroke: #fff;
    stroke-width: 2;
}

@media only screen and (max-width: 1100px) {
    .logo-container {
        width: 250px;
    }
}

@media only screen and (max-width: 768px) {
    .logo-container {
        margin: unset;
        width: 200px;
        top: unset;
        left: 50%;
        bottom: 0;
        transform: translate(-40%, -30%);
    }
}
