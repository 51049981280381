.contact-form {
    width: 100%;
    margin-top: 20px;

    ul {
        padding: 0;
        margin: 0;

        li {
            padding: 0;
            margin: 0;
            list-style: none;
            margin-bottom: 10px;
            overflow: hidden;
            display: block;
            position: relative;
            opacity: 0;
            animation: fadeInUp 2s 2s;
            animation-fill-mode: forwards;
            clear: both;
        }

        li.half {
            width: 49%;
            margin-left: 2%;
            float: left;
            clear: none;

            &:first-child {
                margin-left: 0;
            }
        }
    }

    input[type="text"],
    input[type="email"] {
        width: 100%;
        border: 0;
        background: #115173;
        height: 50px;
        font-size: 16px;
        color: #fff;
        padding: 0 20px;
        box-sizing: border-box;
    }

    input[type="submit"] {
        cursor: pointer;

        &:hover {
            background-color: #ffd700;
            color: #fff;
        }
    }

    textarea {
        width: 100%;
        border: 0;
        background: #115173;
        height: 50px;
        font-size: 16px;
        color: #fff;
        padding: 20px;
        min-height: 150px;
        box-sizing: border-box;
    }

    .flat-button {
        color: #ffd700;
        background: 0 0;
        letter-spacing: 3px;
        text-decoration: none;
        padding: 10px 20px;
        border: 1px solid #ffd700;
        float: right;
        transition: all 0.3s ease;
    }
}

.info-map {
    position: absolute;
    background: #000;
    top: 80px;
    right: 30%;
    z-index: 9999;
    width: 267px;
    padding: 20px;
    color: #fff;
    font-family: "Poppins";
    font-size: 17px;
    font-weight: 300;
    opacity: 0;
    animation: fadeIn 1s 1.5s;
    animation-fill-mode: forwards;

    a {
        text-decoration: none;
    }

    span {
        font-size: 16px;
        display: block;
        padding-top: 20px;
        color: #ffd700;
    }
}

.map-wrap {
    background: rgba(8, 253, 216, 0.1);
    float: right;
    width: 53%;
    height: 100%;
}

.leaflet-container {
    width: 100%;
    height: 100%;
    position: relative;
    opacity: 0;
    animation: backInRight 1s 1.2s;
    animation-fill-mode: forwards;
    font-size: 16px;
}

.map-container {
    position: relative;
    height: 100%;
}

.hide-on-desktop {
    display: none;
}

@media only screen and (max-width: 1100px) {
    .info-map {
        top: unset;
        bottom: 0;
        right: 0;
        font-size: 11px;

        a {
            span {
                font-size: 11px;
            }
        }
        
        span {
            font-size: 11px;
        }
    }
}

@media (max-width: 1100px) {
    .contact-page {
        .text-zone {
            top: 40%;

            h1 {
                left: 7vh;
            }
        }
    }


    .scrollable-contact-content {
        overflow-y: scroll;
        height: 550px;
        padding: 0 30px;

        --scrollbar-width: 8px;

        --mask-height: 32px;

        overflow-y: auto;

        padding-bottom: var(--mask-height);
        padding-top: var(--mask-height);

        --mask-image-content: linear-gradient(
            to bottom,
            transparent,
            black var(--mask-height),
            black calc(100% - var(--mask-height)),
            transparent
        );

        --mask-size-content: calc(100% - var(--scrollbar-width)) 100%;

        --mask-image-scrollbar: linear-gradient(black, black);

        --mask-size-scrollbar: var(--scrollbar-width) 100%;

        mask-image: var(--mask-image-content), var(--mask-image-scrollbar);
        mask-size: var(--mask-size-content), var(--mask-size-scrollbar);

        mask-position:
            0 0,
            100% 0;

        mask-repeat: no-repeat, no-repeat;

        h1 {
            margin-bottom: 30px !important;
        }
    }

    .scrollable-contact-content::-webkit-scrollbar {
        display: none;
    }
}

@media (max-width: 576px) {
    .info-map {
        width: fit-content;
    }
}
