.nav-bar {
    background: #000b19;
    width: 80px;
    height: 100%;
    position: absolute;
    top: 0;
    z-index: 3;
    min-height: 500px;

    .logo {
        display: block;
        padding: 8px 0;

        img {
            display: block;
            margin: 8px auto;
            width: 44px;
            height: auto;

            &.sub-logo {
                width: 50px;
            }
        }
    }

    nav {
        display: block;
        text-align: center;
        position: absolute;
        height: 210px;
        top: 50%;
        margin-top: -120px;
        width: 100%;

        a {
            font-size: 22px;
            color: #4d4d4e;
            display: block;
            line-height: 51px;
            height: 51px;
            position: relative;
            text-decoration: none;

            i {
                transition: all 0.3s ease-out;
            }

            &:hover {
                color: #ebf3ff;

                svg {
                    opacity: 0;
                }

                &::after {
                    opacity: 1;
                }
            }

            &:after {
                content: "";
                font-size: 9px;
                position: absolute;
                bottom: 0;
                display: block;
                width: 100%;
                text-align: center;
                opacity: 0;
                transition: all 0.3s ease-out;
            }

            &:first-child {
                &::after {
                    content: "HOME";
                    letter-spacing: 2px;
                }
            }
        }

        a.about-link {
            &:after {
                content: "ABOUT";
                letter-spacing: 2px;
            }
        }

        a.skills-link {
            &:after {
                content: "SKILLS";
                letter-spacing: 2px;
            }
        }

        a.contact-link {
            &:after {
                content: "CONTACT";
                letter-spacing: 2px;
            }
        }

        a.active {
            svg {
                color: #0264e3;
            }
        }
    }

    ul {
        position: absolute;
        bottom: 20px;
        width: 100%;
        display: block;
        padding: 0;
        list-style: none;
        text-align: center;
        margin: 0;

        li {
            a {
                padding: 7px 0;
                display: block;
                font-size: 22px;
                line-height: 16px;

                &:hover svg {
                    color: #0264e3;
                    animation: rubberBand 1s;
                }
            }
        }
    }

    .nav-menu {
        display: none;
        z-index: 99;
        transition: transform 0.3s ease;
        font-size: 30px;
    }
}

.nav-menu.active {
    transform: rotateZ(180deg) translateY(15px);
  }

.nav-wrapper {
    position: fixed;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #000b19;
    animation: slideInDown .3s forwards;

    a {
        width: 75%;
        padding: 15px 10px;
        border-radius: 20px;
        background-color: #001023;
        text-decoration: none;
        place-items: center;
        display: grid;

        svg {
            font-size: 22px;
        }

        span {
            color: #fff;
            font-size: 16px;
        }

        .nav-menu-item {
            display: flex;
            width: 100px;

            span {
                margin-left: 20px;
            }
        }
    }
    a:not(:first-child) {
        margin-top: 20px;
    }
    a.active {
        svg {
            color: #0264e3
        }
    }
}

@media only screen and (max-width: 1100px) {
    .nav-bar {
        width: 100%;
        height: 80px;
        min-height: 0;

        nav {
            width: 42%;
            position: absolute;
            left: 50%;
            margin-left: -19%;
            float: left;
            overflow: hidden;
            text-align: center;
            height: 100%;
            top: 0;
            margin-top: 0;
            padding-top: 25px;

            a {
                float: left;
                margin-left: 14%;
                line-height: 7px;
                height: 60px;
                -webkit-tap-highlight-color: transparent;

                &:after {
                    bottom: unset;
                    top: 10px;
                }
            }
        }
        ul {
            position: static;
            bottom: 20px;
            width: auto;
            height: 100%;
            line-height: 60px;
            display: block;
            padding: 0;
            list-style: none;
            text-align: center;
            margin: 0;
            float: right;
            padding-top: 19px;

            li {
                float: right;
                height: 60px;
                line-height: 60px;
                margin: 0 10px;
                a {
                    padding: 7px 0;
                    display: block;
                    font-size: 22px;
                    line-height: 16px;
                }
            }
        }

        .logo {
            float: left;
            margin-right: 20px;
            padding: 0 10px;
        }
    }
}

@media only screen and (max-width: 576px) {
    .nav-bar {
        nav {
            display: none;
        }
        ul {
            display: none;
        }
    }
    .nav-menu {
        display: block !important;
        position: absolute;
        top: 50%;
        right: 30px;
        transform: translateY(-50%);
    }
}
